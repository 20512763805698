import { workOrderPriorityLabelMap as priorityLabelMap } from '@/components/workOrders/WorkOrderPriorityLabel';
import type { WorkOrderPriority, WorkOrderStatus } from '@/graphql/types';
import { IDisplayAsset } from '@/modules/assets/types';
import { getAssetNameList } from '@/modules/assets/utils';
import { ICompanySetting } from '@/modules/companies';
import { IDisplayGroup } from '@/modules/groups';
import { IProduct, getProductNameList } from '@/modules/products';
import { IDisplayUser } from '@/modules/users';
import type {
  IWorkOrderCustomFieldDateValue,
  IWorkOrderCustomFieldDatetimeValue,
  IWorkOrderCustomFieldFloatValue,
  IWorkOrderCustomFieldIntValue,
  IWorkOrderCustomFieldSelectValue,
  IWorkOrderCustomFieldTextValue,
  IWorkOrderCustomFieldUserValue,
  IWorkOrderStoppage,
} from '@/modules/workOrders/types/workOrder';
import type { IWorkOrderCustomField } from '@/modules/workOrders/types/workOrderCustomField';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { TFunction } from 'i18next';
import { formatDateToYYYYMD, formatDateToYYYYMDHHmm, getDifferenceInMinutes } from '../date/date';
import { trimAndJoinWithDelimiter } from '../string/string';
import { downloadCSV, getFileNameForDownload, makeCSV } from './csv';
import { escapeStrForCSV } from './escape-string';
import { generateHierarchyInfo } from './generateHierarchyInfo';

export type WorkOrderCSVType = {
  title?: string | undefined;
  asset?: IDisplayAsset;
  product?: IProduct;
  description?: string | null;
  status: WorkOrderStatus;
  assignees?: IDisplayUser[];
  groups?: IDisplayGroup[];
  priority?: WorkOrderPriority;
  dueDate?: Date;
  createdAt: Date;
  createdBy?: IDisplayUser;
  stoppage?: IWorkOrderStoppage;
  stoppageReason?: {
    id: number;
    name: string;
  };
  customFieldTextValues: IWorkOrderCustomFieldTextValue[];
  customFieldIntValues: IWorkOrderCustomFieldIntValue[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValue[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValue[];
  customFieldDateValues: IWorkOrderCustomFieldDateValue[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValue[];
  customFieldUserValues: IWorkOrderCustomFieldUserValue[];
};

type ReturnType = {
  createWorkOrderCSV: (
    companySetting: ICompanySetting,
    customFields: IWorkOrderCustomField[],
    workOrders: WorkOrderCSVType[],
    getStatusString: (value: WorkOrderStatus) => string,
    t: TFunction<string[], undefined>
  ) => string;
  downloadWorkOrderCsv: (
    companySetting: ICompanySetting,
    customFields: IWorkOrderCustomField[],
    workOrders: WorkOrderCSVType[],
    getStatusString: (value: WorkOrderStatus) => string,
    t: TFunction<string[], undefined>,
    prefix: string
  ) => void;
  getDownloadWorkOrderCsvNamePrefix: (template: IWorkOrderTemplateBase) => string;
};

export type WorkOrderDownloadType = 'createdAt' | 'stoppageStartAt' | 'dueDate';

const defaultHeaderKeys: string[] = [
  'title',
  'description',
  'status.task-status',
  'date.due-date',
  'assignee',
  'priority.title',
  'creator',
  'date.created-date',
  'date.down-time-start-time',
  'date.down-time-end-time',
  'date.down-time',
  'stoppage-reason.title',
];

export const useWorkOrderCSV = (): ReturnType => {
  const createWorkOrderCSV = (
    companySetting: ICompanySetting,
    customFields: IWorkOrderCustomField[],
    workOrders: WorkOrderCSVType[],
    getStatusString: (value: WorkOrderStatus) => string,
    t: TFunction<string[], undefined>
  ): string => {
    const { maxAssetHierarchyLevel, assetHierarchies, accessProduct, accessGroup } = companySetting;
    const { headers: assetHeaders, hierarchyArray: assetHierarchyArray } = generateHierarchyInfo(
      t('asset'),
      maxAssetHierarchyLevel,
      assetHierarchies
    );

    const { headers: productHeaders, hierarchyArray: productHierarchyArray } =
      generateHierarchyInfo(t('product'));

    const filteredCustomFields = customFields.filter((entry) => entry.type !== 'file');

    const workOrderCsvData = workOrders.map((workOrder) => {
      const {
        title,
        description,
        status,
        dueDate,
        assignees,
        priority = 'none',
        createdBy,
        createdAt,
        asset,
        product,
        stoppage,
        stoppageReason,
        customFieldTextValues,
        customFieldIntValues,
        customFieldFloatValues,
        customFieldSelectValues,
        customFieldDateValues,
        customFieldDatetimeValues,
        customFieldUserValues,
        groups,
      } = workOrder;

      const assetNameList = getAssetNameList(asset);
      const productNameList = getProductNameList(product);
      const assigneeNames = assignees
        ? trimAndJoinWithDelimiter(assignees.map((user) => user.name))
        : undefined;
      const groupNames = groups
        ? trimAndJoinWithDelimiter(groups.map((group) => group.name))
        : undefined;

      return [
        escapeStrForCSV(title || ''),
        escapeStrForCSV(description || ''),
        getStatusString(status),
        formatDateToYYYYMD(dueDate) || '',
        escapeStrForCSV(assigneeNames || ''),
        priorityLabelMap.get(priority)?.title || '',
        escapeStrForCSV(createdBy?.name || ''),
        formatDateToYYYYMDHHmm(createdAt),
        stoppage?.startAt ? formatDateToYYYYMDHHmm(stoppage.startAt) : '',
        stoppage?.endAt ? formatDateToYYYYMDHHmm(stoppage.endAt) : '',
        stoppage?.startAt && stoppage?.endAt
          ? getDifferenceInMinutes(stoppage.startAt, stoppage.endAt)
          : '',
        stoppageReason ? escapeStrForCSV(stoppageReason.name) : '',
        ...(accessGroup ? [escapeStrForCSV(groupNames || '')] : []),
        ...assetHierarchyArray.map((_, index: number): string =>
          assetNameList[index] ? escapeStrForCSV(assetNameList[index]) : ''
        ),
        ...(accessProduct
          ? productHierarchyArray.map((_, index: number): string =>
              productNameList[index] ? escapeStrForCSV(productNameList[index]) : ''
            )
          : []),
        ...filteredCustomFields
          .map((form) => {
            switch (form.type) {
              case 'text':
                const textValue = customFieldTextValues.find(
                  (values) => values.customFieldId === form.id
                );
                return textValue ? escapeStrForCSV(textValue.value) : '';
              case 'int':
                const intValue = customFieldIntValues.find(
                  (values) => values.customFieldId === form.id
                );
                return intValue ? String(intValue.value) : '';
              case 'float':
                const floatValue = customFieldFloatValues.find(
                  (values) => values.customFieldId === form.id
                );
                return floatValue ? String(floatValue.value) : '';
              case 'select':
                const selectValues = customFieldSelectValues.filter(
                  (values) => values.customFieldId === form.id
                );
                if (form.customFieldSelectConfig && form.customFieldSelectConfig.hasCategory) {
                  // カテゴリーがある場合は、複数列に分ける
                  return [
                    escapeStrForCSV(selectValues.map((entry) => entry.categoryName).join(',')),
                    escapeStrForCSV(selectValues.map((entry) => entry.value).join(',')),
                  ];
                }
                return escapeStrForCSV(selectValues.map((entry) => entry.value).join(','));
              case 'date':
                const dateValue = customFieldDateValues.find(
                  (values) => values.customFieldId === form.id
                );
                return dateValue ? formatDateToYYYYMD(dateValue.value) : '';
              case 'datetime':
                const datetimeValue = customFieldDatetimeValues.find(
                  (values) => values.customFieldId === form.id
                );
                return datetimeValue ? formatDateToYYYYMDHHmm(datetimeValue.value) : '';
              case 'user':
                const usersValues = customFieldUserValues.filter(
                  (values) => values.customFieldId === form.id
                );
                const usersValueString = usersValues.map((values) => values.user.name).join(',');
                return usersValueString ? escapeStrForCSV(usersValueString) : '';
              default:
                throw new Error(`Unknown custom field type: ${form.type}`);
            }
          })
          .flat(),
      ];
    });

    const workOrderHeader = [
      ...defaultHeaderKeys.map((key) => t(key)),
      ...(accessGroup ? [t('group')] : []),
      ...assetHeaders,
      ...(accessProduct ? productHeaders : []),
      ...filteredCustomFields
        .map((form) => {
          if (
            form.type === 'select' &&
            form.customFieldSelectConfig &&
            form.customFieldSelectConfig.hasCategory
          ) {
            return [`${t(form.label)}(${t('category')})`, t(form.label)];
          }
          return [t(form.label)];
        })
        .flat(),
    ];
    return makeCSV(workOrderHeader, workOrderCsvData);
  };

  const downloadWorkOrderCsv = (
    companySetting: ICompanySetting,
    customFields: IWorkOrderCustomField[],
    workOrders: WorkOrderCSVType[],
    getStatusString: (value: WorkOrderStatus) => string,
    t: TFunction<string[], undefined>,
    prefix: string
  ) => {
    const csv = createWorkOrderCSV(companySetting, customFields, workOrders, getStatusString, t);
    downloadCSV(csv, getFileNameForDownload(prefix));
  };
  const getDownloadWorkOrderCsvNamePrefix = (template: IWorkOrderTemplateBase) => {
    return `${template.useRequest ? 'task-via-request' : 'task'}_${template.name}`;
  };
  return {
    createWorkOrderCSV,
    downloadWorkOrderCsv,
    getDownloadWorkOrderCsvNamePrefix,
  };
};
